import React from 'react'
import S3Video from './S3Video'

export default class Header extends React.Component {
  render() {
    return (
      <div className='header-bg'>
        <S3Video id='header-video' src='videos/bg-video.mp4' autoPlay playsinline muted loop preload='auto' />
        <div className='header-content'>
          <div id="title-text">K-pop on Campus</div>
          <div id="subtitle-text">UMD's best kept secret and the stigma that comes with it</div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const ua = navigator.userAgent.toLowerCase()
    const isSafari = ua.indexOf('safari/') >= 0 && ua.indexOf('chrome') < 0

    // Force autoplay on safari
    if (isSafari) {
      const video = document.getElementById('bg-video')

      // Only force play video if muted and has autoplay
      if (video instanceof HTMLMediaElement && video.muted && video.autoplay) {
        setTimeout(() => {
          video.play()
        }, 50)
      }
    }
  }
}
