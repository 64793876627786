import React from 'react'
import PropTypes from 'prop-types'

import { s3Host } from '../constants'

export default class S3Video extends React.Component {
  render() {
    let src = this.props.src

    if (src.startsWith('/')) {
      src = `${s3Host}${src}`
    } else {
      src = `${s3Host}/${src}`
    }

    const attributes = []

    for (const attr in this.props) {
      if (attr === 'src') continue
      else if (attr === 'className') {
        attributes.push(`class=${this.props[attr]}`)
      }

      attributes.push(`${attr}=${this.props[attr]}`)
    }

    const videoHtml = `<video ${attributes.join(' ')}><source src="${src}"></source></video>`

    return <div dangerouslySetInnerHTML={{ __html: videoHtml }}></div>
  }
}

S3Video.propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  autoPlay: PropTypes.bool,
  playsinline: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'metadata', 'auto', '']),
  controls: PropTypes.bool
}
