import React from 'react'
import PropTypes from 'prop-types'

import { s3Host } from '../constants'

export default class S3Image extends React.Component {
  render() {
    let src = this.props.src
    const className = this.props.className

    if (src.startsWith('/')) {
      src = `${s3Host}${src}`
    } else {
      src = `${s3Host}/${src}`
    }

    return <img src={src} className={className}></img>
  }
}

S3Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string
}
